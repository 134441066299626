<template>
  <div class="request-files">
    <div
      class="request-files__notice-container"
      v-if="notices.length && !filesUploaded"
    >
      <tnd-notice
        v-for="(notice, index) of notices"
        :key="index"
        :text="notice.text"
      />
    </div>

    <div class="request-files__section">
      <div class="section-info">
        <div class="section-title">
          <span> Коммерческое предложение </span>
          <b-tooltip
            type="is-light"
            position="is-right"
            class="category-tooltip"
            multilined
          >
            <template v-slot:content>
              Прикрепите коммерческое предложение, оформленное на фирменном бланке вашей организации
            </template>
            <ExclamationInCircleIcon :size="15" />
          </b-tooltip>
        </div>
      </div>
      <div class="columns is-gapless">
        <div class="column is-half">
          <tnd-upload-file name="offer" :disabled="filesUploaded" />
        </div>
      </div>
    </div>
    <div class="request-files__section">
      <div class="section-info">
        <div class="section-title">
          <span> Протокол переговоров </span>
        </div>
      </div>
      <div class="columns is-gapless">
        <div class="column is-half">
          <tnd-upload-file name="protocol" :disabled="filesUploaded" />
        </div>
      </div>
    </div>
    <div class="request-files__section">
      <div class="section-info">
        <div class="section-title">
          <span> Другие файлы или документы </span>
        </div>
      </div>
      <div class="columns is-gapless">
        <div class="column is-half">
          <tnd-upload-file
            name="otherDocs"
            :files="otherDocs"
            multiple
            :disabled="filesUploaded"
          />
        </div>
      </div>
    </div>
    <div class="is-flex is-justify-content-center mt-6">
      <button class="button is-normal main-button purple" @click="submit">
        {{filesUploaded ? "ПРОДОЛЖИТЬ" : "СОХРАНИТЬ"}}
      </button>
    </div>
  </div>
</template>

<script>
import TndUploadFile from "../components/common/TndUploadFile.vue";
import TndNotice from "../components/common/TndNotice.vue";
import ExclamationInCircleIcon from "../components/icons/ExclamationInCircleIcon.vue";
import httpClient from "@/services/http.service";

export default {
  components: { TndUploadFile, TndNotice, ExclamationInCircleIcon },
  name: "RequestFiles",
  data: () => ({
    filesUploaded: false,
    notices: [
      {
        text: "Обязательные документы не загружены. Загрузите файлы.",
      },
    ],
    otherDocs: [
      /* {
        name: "Заявка на поставку ТМЦ.docx",
        date: "12 Декабря 2021, 09:37",
        size: "263",
      }, */
    ],
  }),
  methods: {
    async uploadFile(section, file) {
      let formData = new FormData();
      formData.append("file", file);
      formData.append("section", section);

      await httpClient
        .post(`/restapi/order/${this.orderId}/upload_file`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          console.info("File upload response:", response.data);
        })
        .catch((errors) => {
          console.info("File upload error: ", errors);
        });
    },
    async submit() {
      if (this.filesUploaded) {
        this.$emit("next");
        return;
      }

      if (this.orderFiles.offer == null) {
        this.$buefy.toast.open({
          message: "Загрузите Коммерческое предложение",
          type: "is-danger",
          duration: 5000,
        });
        return;
      }

      const filesToUpload = [];
      for (const [section, value] of Object.entries(this.orderFiles)) {
        if (value && value.fileObject) {
          filesToUpload.push({ section: section, file: value.fileObject });
        }
      }

      const asyncRes = await Promise.all(
        filesToUpload.map(async (item) => {
          await this.uploadFile(item.section, item.file);
          return item;
        })
      );

      this.filesUploaded = true;
      this.$emit("next");
    },
  },
  computed: {
    orderFiles() {
      return this.$store.getters["tenderRequest/orderFiles"];
    },
    orderId() {
      return this.$store.getters["tenderRequest/orderId"];
    },
  },
};
</script>

<style lang="scss">
.request-files__section .b-tooltip.is-multiline.is-medium .tooltip-content {
  line-height: 1.5;
}
</style>

<style lang="scss" scoped>
.request-files {
  &__notice-container {
    margin-bottom: 33px;
  }

  &__section {
    padding: 27px 0 37px;
    & + & {
      border-top: 1px solid #e4e4e4;
    }
    .section-info {
      padding-bottom: 22px;

      .section-title {
        display: flex;
        align-items: center;
        font-style: normal;
        font-weight: 400;
        font-size: 22px;
        line-height: 26px;
        color: #323232;
        margin-bottom: 9px;

        span {
          margin-right: 14px;
        }
      }

      .section-description {
        font-style: normal;
        font-weight: 350;
        font-size: 13px;
        line-height: 1.05em;
        color: #6f6f6f;
      }
    }
  }
}
</style>