<template>
  <div class="send-request__notice-container" v-if="notices.length">
    <div v-if="!formSent">
      <tnd-notice
        v-for="(notice, index) of notices"
        :key="index"
        :text="notice.text"
      />
      <div class="is-flex is-justify-content-center mt-6">
        <button class="button is-normal main-button purple" @click="submit">
          <hammer-icon color="#FFFFFF" style="margin-right: 10px" />
          ПОДАТЬ ЗАЯВКУ
        </button>
      </div>
    </div>
    <div v-else>
      <tnd-notice :text="`Заявка подана. Номер заявки: ${orderId}`" />
    </div>
  </div>
</template>

<script>
import TndNotice from "../components/common/TndNotice.vue";
import HammerIcon from "../components/icons/HammerIcon.vue";
import httpClient from "@/services/http.service";

import { orderStatusIds } from "@/data/statuses";

export default {
  components: {
    TndNotice,
    HammerIcon,
  },
  name: "SendRequest",
  data: () => ({
    formSent: false,
    notices: [
      {
        text: "Статус Вашей заявки: ЧЕРНОВИК. Заявки с этим статусом не могут быть одобрены тендерной комиссией. Вам необходимо заполнить все поля и нажать на кнопку «Подать заявку»",
      },
    ],
  }),
  computed: {
    orderId() {
      return this.$store.getters["tenderRequest/orderId"];
    },
  },
  methods: {
    submit() {
      if (this.formSent) {
        this.$emit("next");
        return;
      }

      httpClient
        .post(`/restapi/order/${this.orderId}/set_status`, {
          status: orderStatusIds.NEW.id,
        })
        .then((response) => {
          const { data } = response;
          if (data && data.status) {
            this.$buefy.toast.open({
              message: `Заявка подана. Номер заявки: ${this.orderId}`,
              type: "is-success",
              duration: 7000,
            });

            this.$store.commit(
              "tenderRequest/setOrderStatusId",
              orderStatusIds.NEW.id
            );

            this.formSent = true;
            this.$emit("next");
            return;
          }

          this.$buefy.toast.open({
            message:
              "Что-то пошло не так, возможно не все поля заполнены правильно",
            type: "is-danger",
            duration: 5000,
          });
        });
    },
  },
};
</script>

<style lang="scss">
</style>