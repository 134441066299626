<template>
  <div>
    <p v-if="getError(errors.tender_id)" class="error has-text-danger mb-6">
      {{ getError(errors.tender_id) }}
    </p>
    <div class="columns" :class="{'form-request-disabled': formSent}">
      <div class="column">
        <tnd-input
          v-model="formData.dt_plan_start"
          class="mb-5"
          input-type="datepicker"
          is-label
          :error="getError(errors.dt_plan_start)"
          @change="calculateDeliveryTime"
        >
          <template #label>
            <p>НАЧАЛО ПОСТАВКИ / ВЫПОЛНЕНИЯ РАБОТ</p>
            <span
              >(из расчета срока подписания договора 7 рабочих дней с момента
              окончания тендера)</span
            >
          </template>
        </tnd-input>
        <tnd-input
          class="mb-5"
          input-label="СРОК ПОСТАВКИ / ВЫПОЛНЕНИЯ РАБОТ, КАЛЕНДАРНЫХ ДНЕЙ"
          v-model="formData.delivery_time"
          :error="getError(errors.delivery_time)"
        />
        <tnd-input
          class="mb-5"
          input-label="ЗАТРАТЫ НА ДОСТАВКУ ДО ОБЬЕКТА (РУБ С НДС)"
          v-model="formData.delivery_cost"
          :error="getError(errors.delivery_cost)"
        />
        <tnd-input
          class="mb-5"
          input-type="vue-select"
          :options="transportTypes"
          input-label="ВИД ТРАНСПОРТА"
          v-model="formData.transport_type"
          :error="getError(errors.transport_type)"
        />
        <tnd-input
          class="mb-5"
          input-label="СРОКИ АВАНСИРОВАНИЯ"
          v-model="formData.prepayment_terms"
          :error="getError(errors.prepayment_terms)"
        />
        <tnd-input
          class="mb-5"
          input-type="dadata"
          input-label="АДРЕС ПОСТАВКИ"
          v-model="formData.delivery_address"
          :error="getError(errors.delivery_address)"
          placeholder="Начните вводить адрес"
        />
        <tnd-input
          class="mb-5"
          input-label="КОНТАКТНОЕ ЛИЦО (ФИО, номер телефона, почта)"
          v-model="formData.contact"
          :error="getError(errors.contact)"
        />
        <tnd-input
          class="mb-5"
          input-label="В СЛУЧАЕ ВЫПОЛНЕНИЯ РАБОТ УКАЗАТЬ ЗАТРАТЫ, КОТОРЫЕ УЧТЕНЫ В ЦЕНЕ"
          v-model="formData.works_include_costs"
          :error="getError(errors.works_include_costs)"
        />
      </div>
      <div class="column">
        <tnd-input
          v-model="formData.dt_plan_end"
          class="mb-5"
          input-type="datepicker"
          input-label="ОКОНЧАНИЕ ПОСТАВКИ / ВЫПОЛНЕНИЯ РАБОТ"
          :error="getError(errors.dt_plan_end)"
          @change="calculateDeliveryTime"
        />
        <tnd-input
          class="mb-5"
          input-type="vue-select"
          :options="supplyConditions"
          input-label="УСЛОВИЯ ПОСТАВКИ"
          v-model="formData.delivery_term_id"
          :error="getError(errors.delivery_term_id)"
        />
        <tnd-input
          class="mb-5"
          input-type="vue-select"
          :options="deliveryConditions"
          input-label="УСЛОВИЯ ДОСТАВКИ"
          v-model="formData.delivery_order"
          :error="getError(errors.delivery_order)"
        />
        <tnd-input
          class="mb-5"
          input-label="ПРОЦЕНТ АВАНСИРОВАНИЯ"
          v-model="formData.prepayment"
          :error="getError(errors.prepayment)"
        />
        <tnd-input
          class="mb-5"
          input-label="СРОКИ ПОСЛЕДУЮЩЕЙ ОПЛАТЫ"
          v-model="formData.payment_terms"
          :error="getError(errors.payment_terms)"
        />
        <tnd-input
          class="mb-5"
          input-label="ГАРАНТИЯ НА УСЛУГИ"
          v-model="formData.warranty"
          :error="getError(errors.warranty)"
        />
        <tnd-input
          class="mb-5"
          input-label="ПРИМЕЧАНИЕ"
          v-model="formData.note"
          :error="getError(errors.note)"
        />
        <tnd-input
          class="mb-5"
          input-label="СРОК ДЕЙСТВИЯ ПРЕДЛОЖЕНИЯ, ДНЕЙ"
          v-model="formData.dt_offer"
          :error="getError(errors.dt_offer)"
        />
        <!--<tnd-input
          class="mb-5"
          input-label="price_nds"
          v-model="formData.price_nds"
          :error="getError(errors.price_nds)"
        />-->
      </div>
    </div>
    <div class="is-flex is-justify-content-center">
      <button class="button is-normal main-button purple" @click="submit()">
        ПРОДОЛЖИТЬ
      </button>
    </div>

    <button
      class="button is-small is-outlined is-pulled-right"
      @click="fillTestData"
    >
      Заполнить тестовыми данными
    </button>
  </div>
</template>

<script>
import TndInput from "../components/common/TndInput.vue";
import httpClient from "@/services/http.service";
import _ from "lodash";

export default {
  components: { TndInput },
  name: "TenderRequestForm",
  data: () => ({
    errors: [],
    formSent: false,
    formData: {
      tender_id: null,
      dt_plan_start: "", //НАЧАЛО ПОСТАВКИ
      dt_plan_end: "", //ОКОНЧАНИЕ ПОСТАВКИ
      delivery_time: "", //СРОК ПОСТАВКИ
      delivery_cost: "", //ЗАТРАТЫ НА ДОСТАВКУ
      delivery_term_id: "", //УСЛОВИЯ ПОСТАВКИ
      delivery_order: "", //УСЛОВИЯ ДОСТАВКИ
      transport_type: "", //ВИД ТРАНСПОРТА
      prepayment: "", //ПРОЦЕНТ АВАНСИРОВАНИЯ
      prepayment_terms: "", //СРОКИ АВАНСИРОВАНИЯ
      payment_terms: "", //СРОКИ ПОСЛЕДУЮЩЕЙ ОПЛАТЫ
      delivery_address: "", //АДРЕС ПОСТАВКИ
      warranty: "", //ГАРАНТИЯ НА УСЛУГИ
      contact: "", //КОНТАКТНОЕ ЛИЦО
      note: "", //ПРИМЕЧАНИЕ
      works_include_costs: "", //В СЛУЧАЕ ВЫПОЛНЕНИЯ РАБОТ УКАЗАТЬ
      dt_offer: "", //СРОК ДЕЙСТВИЯ ПРЕДЛОЖЕНИЯ, ДНЕЙ
      price_nds: "", //СРОК ДЕЙСТВИЯ ПРЕДЛОЖЕНИЯ, ДНЕЙ
    },
  }),
  computed: {
    transportTypes() {
      return this.$store.state.tenderRequest.transportTypes;
    },
    supplyConditions() {
      return this.$store.state.tenderRequest.supplyConditions;
    },
    deliveryConditions() {
      return this.$store.state.tenderRequest.deliveryConditions;
    },
  },
  methods: {
    calculateDeliveryTime() {
      if (this.formData.dt_plan_start && this.formData.dt_plan_end) {
        const date1 = new Date(this.formData.dt_plan_start);
        const date2 = new Date(this.formData.dt_plan_end);
        const diffTime = date2 - date1;
        this.formData.delivery_time = (diffTime > 0) ? Math.ceil(diffTime / (1000 * 60 * 60 * 24)) : 0; 
      }
    },
    submit() {
      if (this.formSent) {
        this.$emit("next");
        return;
      }

      this.errors = [];
      let formDataToSend = _.cloneDeep(this.formData);

      let transportName = formDataToSend.transport_type;
      if (transportName) {
        let transportIndex = null;
        this.transportTypes.forEach((item, index) => {
          if (item.value === transportName) transportIndex = index;
        });
        formDataToSend.transport_type = transportIndex;
      }

      if (formDataToSend.dt_plan_end) {
        formDataToSend.dt_plan_end = this.$moment(
          Date.parse(formDataToSend.dt_plan_end)
        ).format("YYYY-MM-DD");
      }

      if (formDataToSend.dt_plan_start) {
        formDataToSend.dt_plan_start = this.$moment(
          Date.parse(formDataToSend.dt_plan_start)
        ).format("YYYY-MM-DD");
      }

      if (formDataToSend.delivery_time) {
        formDataToSend.delivery_time = this.getTodayDatePlusDays(
          formDataToSend.delivery_time
        );
      }

      if (formDataToSend.dt_offer) {
        formDataToSend.dt_offer = this.getTodayDatePlusDays(
          formDataToSend.dt_offer
        );
      }

      httpClient.post("/restapi/order", formDataToSend).then((response) => {
        const { data } = response;
        if (data.errors) {
          this.$buefy.toast.open({
            message: "Не все поля заполнены правильно",
            type: "is-danger",
            duration: 5000,
          });

          this.errors = data.errors;
        } else {
          this.$buefy.toast.open({
            message: `Черновик заявки создан. Номер заявки: ${data.id}`,
            type: "is-success",
            duration: 7000,
          });

          this.formSent = true;
          
          this.$store.commit("tenderRequest/setOrderId", data.id);
          this.$store.commit("tenderRequest/setOrderData", data);
          this.$emit("next");
        }
      });
    },
    fillTestData() {
      this.formData = {
        tender_id: this.formData.tender_id,
        dt_plan_start: "2022-04-23",
        dt_plan_end: "2022-04-23",
        delivery_time: "2022-04-23",
        delivery_cost: "2",
        delivery_term_id: "3",
        delivery_order: "4",
        transport_type: "car",
        prepayment: "6",
        prepayment_terms: "7",
        payment_terms: "8",
        delivery_address: "г Самара, ул Авроры, д 11",
        warranty: "10",
        contact: "11",
        note: "12",
        works_include_costs: "13",
        dt_offer: "2022-04-23",
      };
    },
    getTodayDatePlusDays(days) {
      const currentDate = new Date();
      currentDate.setDate(currentDate.getDate() + parseInt(days));
      return this.$moment(currentDate).format("YYYY-MM-DD");
    },
    getError(error) {
      return error && Array.isArray(error) && error[0] ? error[0] : "";
    },
  },
  mounted() {
    this.formData.tender_id = this.$route.params.id;
  },
};
</script>

<style>
</style>