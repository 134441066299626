<template>
  <svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1.38268 2.39849L11.8577 0.90266C11.9168 0.894179 11.977 0.898506 12.0343 0.915347C12.0916 0.932189 12.1447 0.96115 12.1898 1.00027C12.2349 1.03939 12.2711 1.08775 12.2959 1.14208C12.3207 1.19641 12.3335 1.25544 12.3335 1.31516V18.6868C12.3335 18.7465 12.3207 18.8054 12.2959 18.8597C12.2712 18.9139 12.2351 18.9623 12.1901 19.0014C12.145 19.0405 12.0921 19.0695 12.0349 19.0864C11.9777 19.1033 11.9176 19.1077 11.8585 19.0993L1.38185 17.6035C1.1832 17.5752 1.00143 17.4762 0.869923 17.3246C0.73842 17.1731 0.666018 16.9792 0.666016 16.7785V3.22349C0.666018 3.02284 0.73842 2.82892 0.869923 2.67736C1.00143 2.52581 1.1832 2.42679 1.38185 2.39849H1.38268ZM2.33352 3.94683V16.0552L10.6668 17.246V2.75599L2.33352 3.94683ZM13.1668 15.8343H15.6668V4.16766H13.1668V2.50099H16.5002C16.7212 2.50099 16.9332 2.58879 17.0894 2.74507C17.2457 2.90135 17.3335 3.11331 17.3335 3.33433V16.6677C17.3335 16.8887 17.2457 17.1006 17.0894 17.2569C16.9332 17.4132 16.7212 17.501 16.5002 17.501H13.1668V15.8343ZM7.50018 10.001L9.83352 13.3343H7.83352L6.50018 11.4293L5.16685 13.3343H3.16685L5.50018 10.001L3.16685 6.66766H5.16685L6.50018 8.57266L7.83352 6.66766H9.83352L7.50018 10.001Z" :fill="color"/>
  </svg>
</template>

<script>
export default {
 name: 'ExcelIcon',
 props: {
   color: {
     type: String,
     default: '#27AE60'
   }
 }
}
</script>

<style>

</style>