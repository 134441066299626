<template>
  <div class="tnd-file-upload">
    <div class="tnd-file-upload__uploaded-files">
      <tnd-file
        v-for="(file, index) of filesList"
        :file="file"
        action="delete"
        @action="deleteFile"
        :key="index"
        :disabled="disabled"
      />
    </div>

    <label
      class="tnd-file-upload__upload-container"
      v-bind:class="[
        disabled ? 'tnd-file-upload__upload-container-disabled' : '',
      ]"
      @dragover.prevent
      @dragleave="dragleave"
      @drop.prevent="uploadFile"
      v-if="multiple || (!multiple && !filesList.length)"
    >
      <input v-if="!disabled" type="file" ref="file" @change="uploadFile" :multiple="multiple" />
      <div class="upload-icon">
        <file-icon />
      </div>
      <div class="upload-text">Выберите файл или перетащите в эту область</div>
    </label>
  </div>
</template>

<script>
import FileIcon from "../icons/FileIcon.vue";
import TrashIcon from "../icons/TrashIcon.vue";
import TndFile from "./TndFile.vue";
import httpClient from "@/services/http.service";

export default {
  components: {
    FileIcon,
    TrashIcon,
    TndFile,
  },
  name: "TndUploadFile",
  props: {
    multiple: Boolean,
    files: {
      type: [Array, Object],
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      default: "",
      required: true,
    },
  },
  data: () => ({
    list: [],
    kilobyte: 1024,
  }),
  computed: {
    filesList: {
      get() {
        return this.list.length ? this.list : this.files;
      },
      set(newVal) {
        this.list = newVal;
      },
    },
  },
  methods: {
    dragover() {},
    dragleave() {},
    uploadFile(e) {
      const files = e.target.files || e.dataTransfer.files;
      const filesList = this.multiple ? Object.values(files) : [files[0]];

      this.addToList(filesList);
    },
    addToList(files) {
      if (!this.multiple && this.filesList.length) return false;

      this.filesList.push(
        ...files.map((file) => ({
          name: file.name,
          size: Math.round(file.size / this.kilobyte),
          date: this.$moment(new Date()).format("DD MMMM YYYY, HH:mm"),
          fileObject: file,
        }))
      );
    },
    deleteFile(file) {
      const index = this.filesList.findIndex(
        (elem) =>
          elem.name === file.name &&
          elem.size === file.size &&
          elem.date === file.date
      );
      this.filesList.splice(index, 1);
    },
  },
  watch: {
    filesList() {
      this.$store.commit("tenderRequest/setOrderFiles", {
        name: this.name,
        files: this.filesList[0],
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/variables";
.tnd-file-upload {
  &__upload-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2.25em;
    background: #fdfbfc;
    border: 1px dashed #c9c9c9;
    box-sizing: border-box;
    border-radius: 10px;

    input[type="file"] {
      display: none;
    }

    .upload-icon {
      width: 1em;
      height: 1.25em;
      margin-bottom: 12px;
    }

    .upload-text {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 1em;
      color: #6f6f6f;
    }

    &-disabled {
      background: #e5e5e5;

      .upload-text {
        color: #a5a3a3 !important
      }
    }
  }
}
</style>