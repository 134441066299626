<template>
  <div class="tender-positions container">
    <div class="columns mb-1">
      <div class="column pt-6 pb-5">
        <tnd-pre-price value="1 855 000 232 руб"></tnd-pre-price>
      </div>
      <div class="column is-flex is-justify-content-end is-align-items-end">
        <a class="tender-positions__link red" href=""
          >Выгрузить в Excel <excel-icon class="icon" color="#A01B5B"
        /></a>
        <a class="tender-positions__link green" href=""
          >Загрузить из Excel<excel-icon class="icon"
        /></a>
      </div>
    </div>
    <div class="tender-positions__table-container mb-6" :class="{'form-request-disabled': formSent}">
      <tnd-table
        :data="orderItems"
        :columns="tableColumns"
        v-slot="{ props, column }"
        @tableChange="tableChange"
      >
        <div v-if="column.type === 'description'">
          <p class="row-title">{{ props.row[column.field].title }}</p>
          <p class="row-description">
            {{ props.row[column.field].description }}
          </p>
        </div>
        <div v-if="column.type === 'editable'">
          <tnd-input v-model="props.row[column.field]" />
        </div>
        <div v-if="column.type === 'checkable'">
          <input type="checkbox" v-model="props.row[column.field]" />
        </div>
        <div v-if="column.type === 'computable'">
          {{ props.row[column.field](USDPrice) }}
        </div>
      </tnd-table>
    </div>
    <div class="is-flex is-justify-content-center">
      <button class="button is-normal main-button purple" @click="submit">
        ПРОДОЛЖИТЬ
      </button>
    </div>
  </div>
</template>

<script>
import TndTable from "../components/common/TndTable.vue";
import ExcelIcon from "../components/icons/ExcelIcon.vue";
import TndInput from "../components/common/TndInput.vue";
import TndPrePrice from "../components/common/TndPrePrice.vue";
import _ from "lodash";
import httpClient from "@/services/http.service";

const MeasuresToWords = {
  '1': 'тонны'
};

export default {
  components: { TndTable, ExcelIcon, TndInput, TndPrePrice },
  data: () => ({
    formSent: false,
    errors: [],
    tender_id: null,
    tableValues: [],
    materialPrice: null,
  }),
  computed: {
    tableColumns() {
      return this.$store.getters["tenderRequest/tenderPosTableColumns"];
    },
    tableData() {
      return this.$store.getters["tenderRequest/tenderPosTableData"];
    },
    USDPrice() {
      return this.$store.getters["tenderRequest/USDPrice"];
    },
    orderId() {
      return this.$store.getters["tenderRequest/orderId"];
    },
    orderData() {
      return this.$store.getters["tenderRequest/orderData"];
    },
    orderItems() {
      if(!this.orderData) return;

      return this.orderData.tender_items.map((item) => {
        return {
          nomenclature: {
            title: item.item_name,
            description: "",
          },
          venderCode: item.item_code,
          measureUnit: MeasuresToWords[item.unit_id],
          quantity: item.count,
          materialUnit: item.price_per_item,
          workUnit: item.price_per_item_work,
          note: item.note,
          order_id: item.order_id,
          tender_item_id: item.tender_item_id,
          usd: false,
          materialPrice(usdPrice = 1) {
            return parseFloat(
              this.quantity * this.materialUnit * (this.usd ? usdPrice : 1)
            ).toFixed(3);
          },
          workPrice(usdPrice = 1) {
            return parseFloat(
              this.quantity * this.workUnit * (this.usd ? usdPrice : 1)
            ).toFixed(3);
          },
          ndsPrice(usdPrice = 1) {
            return parseFloat(
              this.materialUnit * this.workUnit * (this.usd ? usdPrice : 1)
            ).toFixed(3);
          },
        };
      });
    },
  },
  mounted() {
    this.$store.dispatch("tenderRequest/getUSDPrice");
    this.tender_id = this.$route.params.id;
  },
  methods: {
    tableChange(values) {
      this.tableValues = values;
    },
    submit() {
      if (this.formSent) {
        this.$emit("next");
        return;
      }

      this.errors = [];
      let _tableValues = _.cloneDeep(this.tableValues);

      _tableValues = _tableValues.map((item) => {
        return {
          price_per_item: item.materialUnit,
          price_per_item_work: item.workUnit,
          note: item.note,
        };
      });

      httpClient
        .post(`/restapi/order/${this.orderId}/items`, _tableValues)
        .then((response) => {
          const { data } = response;
          if (data.id) {
            this.formSent = true;
            this.$emit("next");
            return;
          }

          this.$buefy.toast.open({
            message:
              "Что-то полшло не так, возможно не все поля заполнены правильно",
            type: "is-danger",
            duration: 5000,
          });
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.tender-positions {
  &__link {
    display: flex;
    justify-content: start;
    align-items: center;
    font-weight: 400;
    font-size: 14px;
    line-height: 1em;

    &.red {
      color: #a01b5b;
    }

    &.green {
      color: #27ae60;
    }

    & + & {
      margin-left: 22px;
    }

    .icon {
      margin-left: 10px;
    }
  }

  &__table-container {
    overflow-x: auto;
  }
}
</style>